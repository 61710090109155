/* eslint-disable react/style-prop-object */
import React from 'react';

const LockOpen = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_15779_2026" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="22">
      <rect x="0.664062" y="0.666992" width="20.6667" height="20.6667" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_15779_2026)">
      <path d="M6.35987 17.8894H15.6332C15.7877 17.8894 15.9148 17.8397 16.0142 17.7404C16.1135 17.6409 16.1632 17.5139 16.1632 17.3594V9.80828C16.1632 9.65371 16.1135 9.5267 16.0142 9.42724C15.9148 9.32792 15.7877 9.27827 15.6332 9.27827H6.35987C6.20531 9.27827 6.07829 9.32792 5.97883 9.42724C5.87952 9.5267 5.82986 9.65371 5.82986 9.80828V17.3594C5.82986 17.5139 5.87952 17.6409 5.97883 17.7404C6.07829 17.8397 6.20531 17.8894 6.35987 17.8894ZM10.9965 14.8755C11.3598 14.8755 11.6658 14.751 11.9147 14.502C12.1637 14.2531 12.2882 13.9471 12.2882 13.5838C12.2882 13.2206 12.1637 12.9145 11.9147 12.6657C11.6658 12.4167 11.3598 12.2922 10.9965 12.2922C10.6333 12.2922 10.3272 12.4167 10.0784 12.6657C9.82936 12.9145 9.70486 13.2206 9.70486 13.5838C9.70486 13.9471 9.82936 14.2531 10.0784 14.502C10.3272 14.751 10.6333 14.8755 10.9965 14.8755ZM6.35987 18.7505C5.97453 18.7505 5.6463 18.615 5.37519 18.344C5.10423 18.0729 4.96875 17.7447 4.96875 17.3594V9.80828C4.96875 9.42293 5.10423 9.09471 5.37519 8.8236C5.6463 8.55264 5.97453 8.41715 6.35987 8.41715H12.7187V6.69493C12.7187 5.73551 13.053 4.92162 13.7215 4.25325C14.3899 3.58474 15.2038 3.25049 16.1632 3.25049C17.0497 3.25049 17.8098 3.53695 18.4434 4.10988C19.0772 4.68295 19.4498 5.38619 19.5614 6.2196C19.5889 6.34216 19.5616 6.45203 19.4793 6.54919C19.3971 6.64635 19.2963 6.69493 19.1771 6.69493C19.0545 6.69493 18.953 6.66128 18.8725 6.59397C18.7918 6.52666 18.7377 6.43337 18.7101 6.31411C18.6184 5.69138 18.3355 5.16861 17.8613 4.74581C17.3873 4.323 16.8212 4.1116 16.1632 4.1116C15.4456 4.1116 14.8356 4.36276 14.3333 4.86507C13.831 5.36739 13.5799 5.97734 13.5799 6.69493V8.41715H15.6332C16.0185 8.41715 16.3468 8.55264 16.6179 8.8236C16.8888 9.09471 17.0243 9.42293 17.0243 9.80828V17.3594C17.0243 17.7447 16.8888 18.0729 16.6179 18.344C16.3468 18.615 16.0185 18.7505 15.6332 18.7505H6.35987Z" fill="white" />
    </g>
  </svg>

);

export default LockOpen;
