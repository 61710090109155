/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
import {
  ChevronLeft,
  ChevronRight,
  ExpandMoreOutlined,
} from '@material-ui/icons';
import axios from 'axios';
import LpFooter from 'components/lp-footer';
import SignupForm from 'components/signup-form';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import { getAppToken } from 'services/auth';
import PagueMenosCarousel from 'components/pague-menos-carousel';
import AlexaEchoPop from '../../images/Alexa-Echo-Pop-Smart.svg';
import AliInCellphone from '../../images/AliInCellphone.png';
import AlivieOBolsoComAli from '../../images/alivie-o-bolso-com-ali.svg';
import BubblesOfPeople from '../../images/bubbles-of-people.svg';
import LockOpen from '../../images/lock-open-solid';
import Lock from '../../images/lock-solid';
import ManPlaying from '../../images/man_playing.png';
import HappyWoman from '../../images/woman_happy.svg';
import Playstation5 from '../../images/playstation_5.png';
import SmartTv from '../../images/smart-tv-with-slogan.png';
import NintendoSwitch from '../../images/nintendo-switch.png';
import AlexaEchoShow from '../../images/Alexa-Echo-Show-5.png';
import SwitchAndEchoPop from '../../images/switch-echo-pop.png';
import ShowAndPop from '../../images/alexa-show-alexa-pop.png';
import TvAndPop from '../../images/tv-alexa-pop.png';
import TvAndShow from '../../images/tv-alexa-show.png';
import PlayAndPop from '../../images/playstation-alexa-pop.png';
import SwitchAndAlexa from '../../images/nintendo-switch-alexa-show.png';
import SwitchAnd2Pop from '../../images/nintendo-switch-pop-pop.png';
import TvAndPlayAnd2Pop from '../../images/tv-playstation-pop-pop.png';
import TvAndPlay from '../../images/playstation-tv.png';
import PagueMenosLogo from '../../images/PagueMenos.svg';
import {
  Answer,
  ArrowButton,
  ArrowIcon,
  ArrowsContainer,
  BackgroundCircle,
  BlackDivider,
  Button,
  CampaingProgress,
  CarouselBox,
  Container,
  Content,
  FaqBox,
  FaqContainer,
  FaqItem,
  Footer,
  Header,
  ImageContainer,
  LeftContent,
  LineLock,
  Prize,
  PrizeList,
  PrizeListContent,
  PrizesBox,
  Progress, ProgressItem, ProgressList, Question, Regulation, RegulationBox, RightContent,
  StyledImage,
} from './styles';
import 'react-multi-carousel/lib/styles.css';

const PagueMenosLandingPageContent = () => {
  const { companyMgm } = useParams();
  const [pathData, setPathData] = React.useState(null);
  const [registrationCount, setRegistrationCount] = React.useState(null);
  const [institutionalIsValid, setInstitutionalIsValid] = React.useState(null);
  const [activeIndex, setActiveIndex] = React.useState(null);

  const stages = [1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 11000];

  const calculateStage = (registrationCountFunc) => {
    let currentStage = 0;

    // eslint-disable-next-line max-len
    const nextStage = stages.find((stage) => registrationCountFunc < stage) || stages[stages.length - 1];

    const currentStageIndex = stages.indexOf(nextStage);
    if (currentStageIndex > 0) {
      currentStage = stages[currentStageIndex - 1];
    }

    const remainingRegistrations = nextStage - registrationCountFunc;

    return {
      currentStage,
      nextStage,
      remainingRegistrations,
    };
  };

  const history = useHistory();

  React.useEffect(() => {
    const checkEndpoint = async () => {
      try {
        const token = await getAppToken('mgm');
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}landing-pages/path/${companyMgm}`,
          { headers: { Authorization: `Bearer ${token}` } },
        );

        if (response.status === 200) {
          setPathData(response.data);
          setInstitutionalIsValid(true);
        }
      } catch (error) {
        setInstitutionalIsValid(false);
      }
    };

    checkEndpoint();
  }, [companyMgm]);

  React.useEffect(() => {
    const checkRegistrationCount = async () => {
      try {
        const token = await getAppToken('mgm');
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}landing-pages-subscriptions/count?landingPagePath=${companyMgm}`,
          { headers: { Authorization: `Bearer ${token}` } },
        );

        if (response.status === 200) {
          setRegistrationCount(response.data.validSubscriptions);
        }
      } catch (error) {
        setRegistrationCount(0);
      }
    };

    checkRegistrationCount();
  }, [companyMgm]);

  const [scrollPosition, setScrollPosition] = React.useState(0);

  const handleScrollLeft = () => {
    const container = document.getElementById('carousel');
    container.scrollLeft -= container.offsetWidth;
    setScrollPosition(container.scrollLeft - container.offsetWidth);
  };

  const handleScrollRight = () => {
    const container = document.getElementById('carousel');
    container.scrollLeft += container.offsetWidth;
    setScrollPosition(container.scrollLeft + container.offsetWidth);
  };

  React.useEffect(() => {
    if (institutionalIsValid === false) {
      history.push('/');
    }
  }, [institutionalIsValid, history]);

  if (institutionalIsValid === null) {
    return false;
  }

  const toggleFaq = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: 'Até quando poderei participar e concorrer aos prêmios?',
      answer: 'Para participar e concorrer, você deverá fazer seu cadastro preenchendo o formulário na página <a href="https://www.ali.com.vc/alivie-o-bolso/paguemenosextrafarma">www.ali.com.vc/alivie-o-bolso/paguemenosextrafarma</a> até o dia 15/11.',
    },
    {
      question: 'Meu benefício Ali já está ativado. Posso participar?',
      answer: 'Sim. Basta você se inscrever no formulário da página <a href="https://www.ali.com.vc/alivie-o-bolso/paguemenosextrafarma">www.ali.com.vc/alivie-o-bolso/paguemenosextrafarma</a> e você concorrerá a um dos prêmios desbloqueados.',
    },
    {
      question: 'Como os prêmios são desbloqueados?',
      answer: 'Os prêmios seguirão uma dinâmica de destrave por quantidade mínima de cadastros: ao atingir o número de 1000 novos cadastros, será realizado um sorteio para a liberação do prêmio disponibilizado para aquela faixa. Ou seja, a cada 1000 novos colaboradores cadastrados, um novo sorteio será realizado.',
    },
    {
      question: 'Como saberei quem ganhou os prêmios sorteados?',
      answer: 'Os ganhadores de cada sorteio serão divulgados nos canais de comunicação oficiais da sua empresa a cada sorteio.',
    },
    {
      question: 'Quais prêmios e quantos sorteios acontecerão?',
      answer: 'Os prêmios são: Nintendo Switch, Playstation 5, TV 50", Alexa Echo Show e Alexa Echo Pop. Seguindo esta ordem:  <br /><br /> <u>Sorteio 01:</u><br /> Ganhador 01: leva um Nintendo Switch | Ganhador 02: leva uma Alexa Echo Pop <br /><u>Sorteio 02:</u> <br />Ganhador 01: leva uma Alexa Echo Show 5 | Ganhador 02: leva uma Alexa Echo Pop <br /><u>Sorteio 03:</u><br /> Ganhador 01: leva uma TV 50" | Ganhador 02: leva uma Alexa Echo Pop <br /><u>Sorteio 04:</u><br /> Ganhador 01: leva uma Alexa Echo Show 5 | Ganhador 02: leva uma Alexa Echo Pop <br /><u>Sorteio 05:</u><br /> Ganhador 01: leva um Nintendo Switch | Ganhador 02: leva uma Alexa Echo Pop <br /><u>Sorteio 06:</u><br /> Ganhador 01: leva um Playstation 5 | Ganhador 02: leva uma Alexa Echo Pop <br /><u>Sorteio 07:</u><br /> Ganhador 01: leva um Nintendo Switch | Ganhador 02: leva uma Alexa Echo Show 5 <br /><u>Sorteio 08:</u><br /> Ganhador 01: leva uma TV 50" | Ganhador 02: leva uma Alexa Echo Show 5 <br /><u>Sorteio 09:</u><br /> Ganhador 01: leva um Nintendo Switch | Ganhador 02: leva uma Alexa Echo Pop | Ganhador 03: leva uma Alexa Echo Pop <br /><u>Sorteio 10:</u><br /> Ganhador 01: leva um kit de TV 50" + Playstation 5 | Ganhador 02: leva uma Alexa Echo Pop | Ganhador 03: leva uma Alexa Echo Pop',
    },
    {
      question: 'Meu nome foi sorteado. Quando receberei meus prêmios?',
      answer: 'Os prêmios serão postados em até 20 dias úteis após o último sorteio e a confirmação de endereço completo por parte dos vencedores.',
    },
  ];

  const stageInfo = calculateStage(registrationCount);

  if (institutionalIsValid) {
    return (
      <Container>
        <Helmet>
          <title>
            Ali |
            {' '}
            {pathData.friendlyName ?? ''}
          </title>
        </Helmet>
        <Header>
          <img src={PagueMenosLogo} alt="Pague menos logo" style={{ height: '70px' }} />
        </Header>
        <Content>
          <LeftContent>
            <ImageContainer>
              <img src={AlivieOBolsoComAli} alt="Alivie o Bolso" />
            </ImageContainer>
            <h1>Benefício que te ajuda a economizar e ganhar prêmios? Com a Ali você tem!</h1>
            <p>Inscreva-se para ativar seu benefício Ali e concorrer a prêmios incríveis.</p>
            <p>
              <b>Não se preocupe:</b>
              {' '}
              você só utiliza o benefício se quiser, combinado?
            </p>
          </LeftContent>
          <RightContent>
            <SignupForm company={pathData.id} origin="mgm" />
            <img src={HappyWoman} alt="Mulher contente, comemorando uma conquista enquanto segura um celular" style={{ height: '600px' }} />
          </RightContent>
        </Content>
        <BlackDivider>
          <div>
            <img src={BubblesOfPeople} alt="Pessoas" style={{ height: '70px' }} />
          </div>
          <div>
            <h1>Participe e chame seus amigos</h1>
            <h2>para desbloquear mais prêmios</h2>
          </div>
        </BlackDivider>
        <PrizesBox>
          <h1>Você pode ganhar um desses prêmios incríveis</h1>
          <PrizeList>
            <PrizeListContent>
              <Prize>
                <h1>PlayStation 5</h1>
                <img src={Playstation5} alt="PlayStation 5" style={{ height: '150px', width: '250px' }} />
              </Prize>
              <Prize>
                <h1>Smart TV 50&ldquo;</h1>
                <img src={SmartTv} alt="Smart TV" style={{ height: '150px', width: '250px' }} />
              </Prize>
              <Prize>
                <h1>Nintendo Switch</h1>
                <img src={NintendoSwitch} alt="Nintendo Switch" style={{ height: '150px', width: '250px' }} />
              </Prize>
              <Prize>
                <h1>Alexa Echo Show 5</h1>
                <img src={AlexaEchoShow} alt="Alexa Echo Show 5" style={{ height: '150px', width: '250px' }} />
              </Prize>
              <Prize>
                <h1>Alexa Echo Pop</h1>
                <img src={AlexaEchoPop} alt="Alexa Echo Pop" style={{ height: '150px', width: '250px' }} />
              </Prize>
              <Prize>
                <h1>Playstation 5 + Smart TV 50&ldquo;</h1>
                <img src={TvAndPlay} alt="Playstation 5 e TV 50" style={{ height: '150px', width: '250px' }} />
              </Prize>
            </PrizeListContent>
          </PrizeList>
          <h2>*Imagens meramente ilustrativas</h2>
        </PrizesBox>
        {registrationCount !== null && (
          <CarouselBox>
            <div>
              <h1>Ajude seus amigos a economizar:</h1>
              <h2>Quanto mais pessoas ativarem o benefício, mais prêmios serão desbloqueados.</h2>
              <h3>
                Faltam apenas
                {' '}
                {stageInfo.remainingRegistrations}
                {' '}
                inscritos
              </h3>
              <h4>PARA DESBLOQUEAR O PRÓXIMO SORTEIO</h4>
            </div>
            <PagueMenosCarousel active={stageInfo.currentStage} />
          </CarouselBox>
        )}
        <CampaingProgress>
          <h1>Ajude seus amigos a economizar:</h1>
          <h2>Quanto mais pessoas ativarem o benefício, mais prêmios serão desbloqueados.</h2>
          <Progress>
            <h1>
              Faltam apenas
              {' '}
              {stageInfo.remainingRegistrations}
              {' '}
              inscritos
            </h1>
            <h2>PARA DESBLOQUEAR O PRÓXIMO SORTEIO</h2>
            <ProgressList id="carousel">
              <ProgressItem>
                <h1>Sorteio 01:</h1>
                <h1>Ganhador 01:</h1>
                <h2>NINTENDO SWITCH</h2>
                <h1>Ganhador 02:</h1>
                <h2>ALEXA ECHO POP</h2>
                <br />
                <BackgroundCircle
                  active={stageInfo.currentStage === 1000}
                >
                  <span />
                  <span />
                  <span />
                  <span />
                </BackgroundCircle>
                <StyledImage
                  src={SwitchAndEchoPop}
                  alt="Nintendo Switch e Alexa Echo Pop"
                  style={stageInfo.currentStage === 1000
                    ? { height: '150px', width: '250px' }
                    : { height: '130px', width: '200px' }}
                  active={stageInfo.currentStage < 1000}
                />
              </ProgressItem>
              <LineLock large unlock={stageInfo.currentStage >= 2000}>
                <span />
                <span />
                <span />
                <h1>--------------</h1>
                {stageInfo.currentStage >= 2000
                  ? <LockOpen /> : <Lock />}
              </LineLock>
              <ProgressItem>
                <h1>Sorteio 02:</h1>
                <h1>Ganhador 01:</h1>
                <h2>ALEXA ECHO SHOW 5</h2>
                <h1>Ganhador 02:</h1>
                <h2>ALEXA ECHO POP</h2>
                <br />
                <BackgroundCircle active={stageInfo.currentStage === 2000}>
                  <span />
                  <span />
                  <span />
                  <span />
                </BackgroundCircle>
                <StyledImage
                  src={ShowAndPop}
                  alt="Alexa Echo Show e Alexa Echo Pop"
                  style={stageInfo.currentStage === 2000
                    ? { height: '160px', width: '250px' }
                    : { height: '110px', width: '180px', marginTop: '-90px' }}
                  active={stageInfo.currentStage < 2000}
                />
              </ProgressItem>
              <LineLock large unlock={stageInfo.currentStage >= 3000}>
                <span />
                <span />
                <span />
                <h1>--------------</h1>
                {stageInfo.currentStage >= 3000
                  ? <LockOpen /> : <Lock />}
              </LineLock>
              <ProgressItem>
                <h1>Sorteio 03:</h1>
                <h1>Ganhador 01:</h1>
                <h2>TV 50&ldquo;</h2>
                <h1>Ganhador 02:</h1>
                <h2>ALEXA ECHO POP</h2>
                <br />
                <BackgroundCircle active={stageInfo.currentStage === 3000}>
                  <span />
                  <span />
                  <span />
                  <span />
                </BackgroundCircle>
                <StyledImage
                  src={TvAndPop}
                  alt="TV 50 e Alexa Echo Pop"
                  style={stageInfo.currentStage === 3000
                    ? { height: '150px', width: '250px' }
                    : { height: '110px', width: '190px', marginTop: '-90px' }}
                  active={stageInfo.currentStage < 3000}
                />
              </ProgressItem>
              <LineLock large unlock={stageInfo.currentStage >= 4000}>
                <span />
                <span />
                <span />
                <h1>--------------</h1>
                {stageInfo.currentStage >= 4000
                  ? <LockOpen /> : <Lock />}
              </LineLock>
              <ProgressItem>
                <h1>Sorteio 04:</h1>
                <h1>Ganhador 01:</h1>
                <h2>ALEXA ECHO SHOW 5</h2>
                <h1>Ganhador 02:</h1>
                <h2>ALEXA ECHO POP</h2>
                <br />
                <BackgroundCircle active={stageInfo.currentStage === 4000}>
                  <span />
                  <span />
                  <span />
                  <span />
                </BackgroundCircle>
                <StyledImage
                  src={ShowAndPop}
                  alt="Alexa Echo Show e Alexa Echo Pop"
                  style={stageInfo.currentStage === 4000
                    ? { height: '160px', width: '250px' }
                    : { height: '110px', width: '180px', marginTop: '-90px' }}
                  active={stageInfo.currentStage < 4000}
                />
              </ProgressItem>
              <LineLock large unlock={stageInfo.currentStage >= 5000}>
                <span />
                <span />
                <span />
                <h1>--------------</h1>
                {stageInfo.currentStage >= 5000
                  ? <LockOpen /> : <Lock />}
              </LineLock>
              <ProgressItem>
                <h1>Sorteio 05:</h1>
                <h1>Ganhador 01:</h1>
                <h2>NINTENDO SWITCH</h2>
                <h1>Ganhador 02:</h1>
                <h2>ALEXA ECHO POP</h2>
                <br />
                <BackgroundCircle active={stageInfo.currentStage === 5000}>
                  <span />
                  <span />
                  <span />
                  <span />
                </BackgroundCircle>
                <StyledImage
                  src={SwitchAndEchoPop}
                  alt="Nintendo Switch e Alexa Echo Pop"
                  style={stageInfo.currentStage === 5000
                    ? { height: '150px', width: '250px' }
                    : { height: '110px', width: '200px', marginTop: '-90px' }}
                  active={stageInfo.currentStage < 5000}
                />
              </ProgressItem>
              <LineLock large unlock={stageInfo.currentStage >= 6000}>
                <span />
                <span />
                <span />
                <h1>--------------</h1>
                {stageInfo.currentStage >= 6000
                  ? <LockOpen /> : <Lock />}
              </LineLock>
              <ProgressItem>
                <h1>Sorteio 06:</h1>
                <h1>Ganhador 01:</h1>
                <h2>PLAYSTATION 5</h2>
                <h1>Ganhador 02:</h1>
                <h2>ALEXA ECHO POP</h2>
                <br />
                <BackgroundCircle active={stageInfo.currentStage === 6000}>
                  <span />
                  <span />
                  <span />
                  <span />
                </BackgroundCircle>
                <StyledImage
                  src={PlayAndPop}
                  alt="Playstation e Alexa Echo Pop"
                  style={stageInfo.currentStage === 6000
                    ? { height: '160px', width: '210px' }
                    : { height: '130px', width: '150px' }}
                  active={stageInfo.currentStage < 6000}
                />
              </ProgressItem>
              <LineLock large unlock={stageInfo.currentStage >= 7000}>
                <span />
                <span />
                <span />
                <h1>--------------</h1>
                {stageInfo.currentStage >= 7000
                  ? <LockOpen /> : <Lock />}
              </LineLock>
              <ProgressItem>
                <h1>Sorteio 07:</h1>
                <h1>Ganhador 01:</h1>
                <h2>NINTENDO SWITCH</h2>
                <h1>Ganhador 02:</h1>
                <h2>ALEXA ECHO SHOW 5</h2>
                <br />
                <BackgroundCircle active={stageInfo.currentStage === 7000}>
                  <span />
                  <span />
                  <span />
                  <span />
                </BackgroundCircle>
                <StyledImage
                  src={SwitchAndAlexa}
                  alt="Nintendo Switch e Alexa Echo Show"
                  style={stageInfo.currentStage === 7000
                    ? { height: '160px', width: '250px' }
                    : { height: '120px', width: '180px' }}
                  active={stageInfo.currentStage < 7000}
                />
              </ProgressItem>
              <LineLock large unlock={stageInfo.currentStage >= 8000}>
                <span />
                <span />
                <span />
                <h1>--------------</h1>
                {stageInfo.currentStage >= 8000
                  ? <LockOpen /> : <Lock />}
              </LineLock>
              <ProgressItem>
                <h1>Sorteio 08:</h1>
                <h1>Ganhador 01:</h1>
                <h2>TV 50&ldquo;</h2>
                <h1>Ganhador 02:</h1>
                <h2>ALEXA ECHO SHOW 5</h2>
                <br />
                <BackgroundCircle active={stageInfo.currentStage === 8000}>
                  <span />
                  <span />
                  <span />
                  <span />
                </BackgroundCircle>
                <StyledImage
                  src={TvAndShow}
                  alt="TV 50 e Alexa Echo Show"
                  style={stageInfo.currentStage === 8000
                    ? { height: '150px', width: '250px' }
                    : { height: '100px', width: '180px', marginTop: '-90px' }}
                  active={stageInfo.currentStage < 8000}
                />
              </ProgressItem>
              <LineLock large unlock={stageInfo.currentStage >= 9000}>
                <span />
                <span />
                <span />
                <h1>--------------</h1>
                {stageInfo.currentStage >= 9000
                  ? <LockOpen /> : <Lock />}
              </LineLock>
              <ProgressItem>
                <h1>Sorteio 09:</h1>
                <h1>Ganhador 01:</h1>
                <h2>NINTENDO SWITCH</h2>
                <h1>Ganhador 02:</h1>
                <h2>ALEXA ECHO POP</h2>
                <h1>Ganhador 03:</h1>
                <h2>ALEXA ECHO POP</h2>
                {stageInfo.currentStage === 9000 && (
                  <br />
                )}
                <BackgroundCircle active={stageInfo.currentStage === 9000}>
                  <span />
                  <span />
                  <span />
                  <span />
                </BackgroundCircle>
                <StyledImage
                  src={SwitchAnd2Pop}
                  alt="Nintendo Switch e 2 Alexa Echo Pop"
                  style={stageInfo.currentStage === 9000
                    ? { height: '160px', width: '250px' }
                    : { height: '110px', width: '190px', marginTop: '-90px' }}
                  active={stageInfo.currentStage < 9000}
                />
              </ProgressItem>
              <LineLock large unlock={stageInfo.currentStage >= 10000}>
                <span />
                <span />
                <span />
                <h1>--------------</h1>
                {stageInfo.currentStage >= 10000
                  ? <LockOpen /> : <Lock />}
              </LineLock>
              <ProgressItem>
                <h1>Sorteio 10:</h1>
                <h1>Ganhador 01:</h1>
                <h2>TV 50&ldquo; + PLAYSTATION 5</h2>
                <h1>Ganhador 02:</h1>
                <h2>ALEXA ECHO POP</h2>
                <h1>Ganhador 03:</h1>
                <h2>ALEXA ECHO POP</h2>
                {stageInfo.currentStage === 10000 && (
                  <br />
                )}
                <BackgroundCircle active={stageInfo.currentStage === 10000}>
                  <span />
                  <span />
                  <span />
                  <span />
                </BackgroundCircle>
                <StyledImage
                  src={TvAndPlayAnd2Pop}
                  alt="TV 50, Playsation 5 e 2 Alexa Echo Pop"
                  style={stageInfo.currentStage === 10000
                    ? { height: '165px', width: '250px' }
                    : { height: '130px', width: '190px' }}
                  active={stageInfo.currentStage < 10000}
                />
              </ProgressItem>
            </ProgressList>
            <ArrowsContainer>
              <ArrowButton aria-label="scroll left" onClick={handleScrollLeft} disabled={scrollPosition <= 0}>
                <ChevronLeft />
              </ArrowButton>
              <ArrowButton aria-label="scroll right" onClick={handleScrollRight}>
                <ChevronRight />
              </ArrowButton>
            </ArrowsContainer>
          </Progress>
        </CampaingProgress>
        <FaqBox>
          <div>
            <img src={ManPlaying} alt="Homem jogando" />
          </div>
          <div>
            <h2>Regras da Campanha</h2>
            <FaqContainer>
              {faqData.map((item, index) => (
                <FaqItem key={item.answer}>
                  <Question onClick={() => toggleFaq(index)}>
                    {item.question}
                    <ArrowIcon isOpen={activeIndex === index}><ExpandMoreOutlined /></ArrowIcon>
                  </Question>
                  <Answer
                    isOpen={activeIndex === index}
                    dangerouslySetInnerHTML={{ __html: item.answer }}
                  />
                </FaqItem>
              ))}
            </FaqContainer>
          </div>
        </FaqBox>
        <RegulationBox>
          <Regulation>
            <div>
              <h2>Acesse e confira o regulamento completo</h2>
              <Button href="https://qrco.de/regulamento-alivie-o-bolso-02" target="_blank">
                Consulte aqui
              </Button>
            </div>
            <img src={AliInCellphone} alt="Mulher segurando o celular e abrindo o app da AliCrédito" />
          </Regulation>
        </RegulationBox>
        <Footer>
          <LpFooter />
        </Footer>
      </Container>
    );
  }

  return null;
};

export default PagueMenosLandingPageContent;
