/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/prop-types */
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import PropTypes from 'prop-types';
import {
  BackgroundCircle, Item, LineLock, StyledImage,
} from './styles';
import LockOpen from '../../images/lock-open-solid';
import Lock from '../../images/lock-solid';
import HeadphoneJbl from '../../images/fone-jbl.svg';
import SmartwatchXiaomi from '../../images/smartwatch-xiaomi.png';
import GrillOster from '../../images/grill-oster.svg';
import Airfryer from '../../images/airfryer.svg';
import AlexaEchoPop from '../../images/Alexa-Echo-Pop-Smart.svg';

const VipalCarousel = ({ active, activeIndex }) => {
  const carouselRef = React.useRef();

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      partialVisibilityGutter: 20,
    },
    tablet: {
      breakpoint: { max: 1024, min: 501 },
      items: 2,
      partialVisibilityGutter: 20,
    },
    mobile: {
      breakpoint: { max: 501, min: 0 },
      items: 1,
      partialVisibilityGutter: 20,
    },
  };

  React.useEffect(() => {
    if (carouselRef.current && typeof active === 'number') {
      const slideIndex = activeIndex === -1 ? 0 : activeIndex;
      carouselRef.current.goToSlide(slideIndex, true);
    }
  }, [active]);

  return (
    <Carousel responsive={responsive} ref={carouselRef} partialVisible>
      <Item>
        <div>
          <h1>Sorteio 01:</h1>
          <h2>Ganhador 01:</h2>
          <h3>Fone JBL</h3>
          <BackgroundCircle
            active={active === 115}
          >
            <span />
            <span />
            <span />
            <span />
          </BackgroundCircle>
          <StyledImage
            src={HeadphoneJbl}
            alt="Fone JBL"
            style={active === 115
              ? { height: '150px', width: '250px' }
              : { height: '130px', width: '200px' }}
            active={active < 115}
          />
        </div>
        <div>
          <LineLock large unlock={active >= 115}>
            <span />
            <span />
            <span />
            <div className="line" />
            {active >= 115 ? <LockOpen /> : <Lock />}
          </LineLock>
        </div>
      </Item>
      <Item>
        <div>
          <h1>Sorteio 02:</h1>
          <h2>Ganhador 01:</h2>
          <h3>Smartwatch Haylou</h3>
          <BackgroundCircle active={active === 230}>
            <span />
            <span />
            <span />
            <span />
          </BackgroundCircle>
          <StyledImage
            src={SmartwatchXiaomi}
            alt="Smartwatch Xiaomi Haylou"
            style={active === 230
              ? { height: '160px', width: '170px' }
              : { height: '120px', width: '120px' }}
            active={active < 230}
          />
        </div>
        <div>
          <LineLock large unlock={active >= 230}>
            <span />
            <span />
            <span />
            <div className="line" />
            {active >= 230 ? <LockOpen /> : <Lock />}
          </LineLock>
        </div>
      </Item>
      <Item>
        <div>
          <h1>Sorteio 03:</h1>
          <h2>Ganhador 01:</h2>
          <h3>Grill Oster</h3>
          <BackgroundCircle
            active={active === 345}
          >
            <span />
            <span />
            <span />
            <span />
          </BackgroundCircle>
          <StyledImage
            src={GrillOster}
            alt="Grill Oster"
            style={active === 345
              ? { height: '160px', width: '250px' }
              : { height: '110px', width: '180px', marginTop: '-90px' }}
            active={active < 345}
          />
        </div>
        <div>
          <LineLock large unlock={active >= 345}>
            <span />
            <span />
            <span />
            <div className="line" />
            {active >= 345 ? <LockOpen /> : <Lock />}
          </LineLock>
        </div>
      </Item>
      <Item>
        <div>
          <h1>Sorteio 04:</h1>
          <h2>Ganhador 01:</h2>
          <h3>Airfryer</h3>
          <BackgroundCircle
            active={active === 460}
          >
            <span />
            <span />
            <span />
            <span />
          </BackgroundCircle>
          <StyledImage
            src={Airfryer}
            alt="Airfryer"
            style={active === 460
              ? { height: '150px', width: '250px' }
              : { height: '110px', width: '200px', marginTop: '-90px' }}
            active={active < 460}
          />
        </div>
        <div>
          <LineLock large unlock={active >= 460}>
            <span />
            <span />
            <span />
            <div className="line" />
            {active >= 460 ? <LockOpen /> : <Lock />}
          </LineLock>
        </div>
      </Item>
      <Item>
        <div>
          <h1>Sorteio 05:</h1>
          <h2>Ganhador 01:</h2>
          <h3>Alexa Echo Pop</h3>
          <BackgroundCircle active={active === 575}>
            <span />
            <span />
            <span />
            <span />
          </BackgroundCircle>
          <StyledImage
            src={AlexaEchoPop}
            alt="Alexa Echo Pop"
            style={active === 575
              ? { height: '180px', width: '220px' }
              : { height: '110px', width: '190px', marginTop: '-90px' }}
            active={active < 575}
          />
        </div>
        <div>
          <LineLock large unlock={active >= 575}>
            <span />
            <span />
            <span />
            <div className="line" />
            {active >= 575 ? <LockOpen /> : <Lock />}
          </LineLock>
        </div>
      </Item>
      <Item>
        <div>
          <h1>Sorteio 06:</h1>
          <h2>Ganhador 01:</h2>
          <h3>Fone JBL</h3>
          <BackgroundCircle
            active={active === 690}
          >
            <span />
            <span />
            <span />
            <span />
          </BackgroundCircle>
          <StyledImage
            src={HeadphoneJbl}
            alt="Fone JBL"
            style={active === 690
              ? { height: '150px', width: '250px' }
              : { height: '130px', width: '200px' }}
            active={active < 690}
          />
        </div>
        <div>
          <LineLock large unlock={active >= 690}>
            <span />
            <span />
            <span />
            <div className="line" />
            {active >= 690 ? <LockOpen /> : <Lock />}
          </LineLock>
        </div>
      </Item>
      <Item>
        <div>
          <h1>Sorteio 07:</h1>
          <h2>Ganhador 01:</h2>
          <h3>Smartwatch Haylou</h3>
          <BackgroundCircle active={active === 805}>
            <span />
            <span />
            <span />
            <span />
          </BackgroundCircle>
          <StyledImage
            src={SmartwatchXiaomi}
            alt="Smartwatch Xiaomi Haylou"
            style={active === 805
              ? { height: '160px', width: '170px' }
              : { height: '120px', width: '120px' }}
            active={active < 805}
          />
        </div>
        <div>
          <LineLock large unlock={active >= 805}>
            <span />
            <span />
            <span />
            <div className="line" />
            {active >= 805 ? <LockOpen /> : <Lock />}
          </LineLock>
        </div>
      </Item>
      <Item>
        <div>
          <h1>Sorteio 08:</h1>
          <h2>Ganhador 01:</h2>
          <h3>Grill Oster</h3>
          <BackgroundCircle
            active={active === 920}
          >
            <span />
            <span />
            <span />
            <span />
          </BackgroundCircle>
          <StyledImage
            src={GrillOster}
            alt="Grill Oster"
            style={active === 920
              ? { height: '160px', width: '250px' }
              : { height: '110px', width: '180px', marginTop: '-90px' }}
            active={active < 920}
          />
        </div>
        <div>
          <LineLock large unlock={active >= 920}>
            <span />
            <span />
            <span />
            <div className="line" />
            {active >= 920 ? <LockOpen /> : <Lock />}
          </LineLock>
        </div>
      </Item>
      <Item>
        <div>
          <h1>Sorteio 09:</h1>
          <h2>Ganhador 01:</h2>
          <h3>Airfryer</h3>
          <BackgroundCircle
            active={active === 1035}
          >
            <span />
            <span />
            <span />
            <span />
          </BackgroundCircle>
          <StyledImage
            src={Airfryer}
            alt="Airfryer"
            style={active === 1035
              ? { height: '150px', width: '250px' }
              : { height: '110px', width: '200px', marginTop: '-90px' }}
            active={active < 1035}
          />
        </div>
        <div>
          <LineLock large unlock={active >= 1035}>
            <span />
            <span />
            <span />
            <div className="line" />
            {active >= 1035 ? <LockOpen /> : <Lock />}
          </LineLock>
        </div>
      </Item>
      <Item>
        <div>
          <h1>Sorteio 10:</h1>
          <h2>Ganhador 01:</h2>
          <h3>Alexa Echo Pop</h3>
          <BackgroundCircle active={active === 1150}>
            <span />
            <span />
            <span />
            <span />
          </BackgroundCircle>
          <StyledImage
            src={AlexaEchoPop}
            alt="Alexa Echo Pop"
            style={active === 1150
              ? { height: '180px', width: '220px' }
              : { height: '110px', width: '190px', marginTop: '-90px' }}
            active={active < 1150}
          />
        </div>
        <div>
          <LineLock large unlock={active >= 1150}>
            <span />
            <span />
            <span />
            <div className="line" />
            {active >= 1150 ? <LockOpen /> : <Lock />}
          </LineLock>
        </div>
      </Item>
    </Carousel>
  );
};

VipalCarousel.propTypes = {
  active: PropTypes.number.isRequired,
  activeIndex: PropTypes.number.isRequired,
};

export default VipalCarousel;
