import axios from 'axios';

export async function getAppToken(origin) {
  const tokens = {
    form: {
      clientId: process.env.REACT_APP_FORM_CLIENT_ID,
      clientSecret: process.env.REACT_APP_FORM_SECRET,
    },
    institutional: {
      clientId: process.env.REACT_APP_INSTITUTIONAL_CLIENT_ID,
      clientSecret: process.env.REACT_APP_INSTITUTIONAL_SECRET,
    },
    live: {
      clientId: process.env.REACT_APP_LIVE_CLIENT_ID,
      clientSecret: process.env.REACT_APP_LIVE_SECRET,
    },
    mgm: {
      clientId: process.env.REACT_APP_MGM_CLIENT_ID,
      clientSecret: process.env.REACT_APP_MGM_SECRET,
    },
  };

  const token = tokens[origin];

  const auth = await axios.post(`${process.env.REACT_APP_BACKEND}oauth/access_token/`, {
    client_id: token.clientId,
    client_secret: token.clientSecret,
  });

  return auth.data.jwt;
}
