/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
import {
  ChevronLeft,
  ChevronRight,
  ExpandMoreOutlined,
} from '@material-ui/icons';
import axios from 'axios';
import LpFooter from 'components/lp-footer';
import SignupForm from 'components/signup-form';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import { getAppToken } from 'services/auth';
import ExpressoNepomucenoCarousel from 'components/expresso-nepomuceno-carousel';
import AlexaEchoPop from '../../images/Alexa-Echo-Pop-Smart.svg';
import AliInCellphone from '../../images/AliInCellphone.png';
import AlivieOBolsoComAli from '../../images/alivie-o-bolso-com-ali.svg';
import BubblesOfPeople from '../../images/bubbles-of-people.svg';
import LockOpen from '../../images/lock-open-solid';
import Lock from '../../images/lock-solid';
import ManPlaying from '../../images/man_playing.png';
import HappyWoman from '../../images/woman_happy.svg';
import Playstation5 from '../../images/playstation_5.png';
import SmartTv from '../../images/smart-tv-with-slogan.png';
import ExpressoNepomucenoLogo from '../../images/expresso-nepomuceno-logotipo.png';
import HeadphoneJbl from '../../images/fone-jbl.svg';
import SmartwatchXiaomi from '../../images/smartwatch-xiaomi.png';
import GrillOster from '../../images/grill-oster.svg';
import Airfryer from '../../images/airfryer.svg';
import AliIso from '../../images/isotipo_ali.svg';
import {
  Answer,
  ArrowButton,
  ArrowIcon,
  ArrowsContainer,
  BackgroundCircle,
  BlackDivider,
  Button,
  CampaignProgress,
  CarouselBox,
  Container,
  Content,
  FaqBox,
  FaqContainer,
  FaqItem,
  Footer,
  Header,
  ImageContainer,
  LeftContent,
  LineLock,
  Prize,
  PrizeList,
  PrizeListContent,
  PrizesBox,
  Progress, ProgressItem, ProgressList, Question, Regulation, RegulationBox, RightContent,
  StyledImage,
} from './styles';
import 'react-multi-carousel/lib/styles.css';

const ExpressoNepomucenoLandingPageContent = () => {
  const { companyMgm } = useParams();
  const [pathData, setPathData] = React.useState(null);
  const [registrationCount, setRegistrationCount] = React.useState(null);
  const [institutionalIsValid, setInstitutionalIsValid] = React.useState(null);
  const [activeIndex, setActiveIndex] = React.useState(null);

  const stages = [300, 600, 900, 1200, 1500, 2100, 2800];

  const calculateStage = (registrationCountFunc) => {
    let currentStage = 0;

    // eslint-disable-next-line max-len
    const nextStage = stages.find((stage) => registrationCountFunc < stage) || stages[stages.length - 1];
    const currentStageIndex = stages.indexOf(nextStage);
    if (nextStage === stages[stages.length - 1] && registrationCountFunc < stages[stages.length - 1]) {
      currentStage = stages[currentStageIndex - 1];
    } else if (currentStageIndex === stages.length - 1) {
      currentStage = stages[currentStageIndex];
    } else if (currentStageIndex > 0) {
      currentStage = stages[currentStageIndex - 1];
    }

    const remainingRegistrations = nextStage - registrationCountFunc;
    return {
      currentStage,
      nextStage,
      remainingRegistrations,
      currentStageIndex,
    };
  };

  const history = useHistory();

  React.useEffect(() => {
    const checkEndpoint = async () => {
      try {
        const token = await getAppToken('mgm');
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}landing-pages/path/${companyMgm}`,
          { headers: { Authorization: `Bearer ${token}` } },
        );

        if (response.status === 200) {
          setPathData(response.data);
          setInstitutionalIsValid(true);
        }
      } catch (error) {
        setInstitutionalIsValid(false);
      }
    };

    checkEndpoint();
  }, [companyMgm]);

  React.useEffect(() => {
    const checkRegistrationCount = async () => {
      try {
        const token = await getAppToken('mgm');
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}landing-pages-subscriptions/count?landingPagePath=${companyMgm}`,
          { headers: { Authorization: `Bearer ${token}` } },
        );

        if (response.status === 200) {
          setRegistrationCount(response.data.validSubscriptions);
        }
      } catch (error) {
        setRegistrationCount(0);
      }
    };

    checkRegistrationCount();
  }, [companyMgm]);

  const [scrollPosition, setScrollPosition] = React.useState(0);

  const handleScrollLeft = () => {
    const container = document.getElementById('carousel');
    container.scrollLeft -= container.offsetWidth;
    setScrollPosition(container.scrollLeft - container.offsetWidth);
  };

  const handleScrollRight = () => {
    const container = document.getElementById('carousel');
    container.scrollLeft += container.offsetWidth;
    setScrollPosition(container.scrollLeft + container.offsetWidth);
  };

  React.useEffect(() => {
    if (institutionalIsValid === false) {
      history.push('/');
    }
  }, [institutionalIsValid, history]);

  if (institutionalIsValid === null) {
    return false;
  }

  const toggleFaq = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: 'Até quando poderei participar e concorrer aos prêmios?',
      answer: 'Para participar e concorrer, você deverá fazer seu cadastro preenchendo o formulário na página <a href="https://www.ali.com.vc/alivie-o-bolso/expressonepomuceno">www.ali.com.vc/alivie-o-bolso/expressonepomuceno</a> até o dia 08/12.',
    },
    {
      question: 'Meu benefício Ali já está ativado. Posso participar?',
      answer: 'Sim. Basta você se inscrever no formulário da página <a href="https://www.ali.com.vc/alivie-o-bolso/expressonepomuceno">www.ali.com.vc/alivie-o-bolso/expressonepomuceno</a> e você concorrerá a um dos prêmios desbloqueados.',
    },
    {
      question: 'Como os prêmios são desbloqueados?',
      answer: 'Os prêmios seguirão uma dinâmica de destrave por quantidade mínima de novos cadastros por sorteio. Ao final da ação, para liberar o sorteio do prêmio máximo (01 Playstation 05), a soma dos cadastros deverá ser 2800. Assim, ao atingir as metas descritas abaixo, os prêmios serão desbloqueados a cada a medida que cada meta for alcançada. Ou seja, cada sorteio requer um número específico de novos cadastros realizados, seguindo a ordem: <br /><br /> <u>Sorteio 01 (300 cadastros):</u><br /> Ganhador 01: leva um Fone JBL <br /><u>Sorteio 02 (600 cadastros):</u> <br />Ganhador 01: leva um smartwatch Haylou <br /><u>Sorteio 03 (900 cadastros):</u><br /> Ganhador 01: leva uma Alexa Echo Pop Amazon <br /><u>Sorteio 04 (1200 cadastros):</u><br /> Ganhador 01: leva um grill Oster <br /><u>Sorteio 05 (1500 cadastros):</u><br /> Ganhador 01: leva uma Airfryer Mondial <br /><u>Sorteio 06 (2100 cadastros):</u><br /> Ganhador 01: leva uma TV 50 polegadas Philips <br /><u>Sorteio 07 (2800 cadastros):</u><br /> Ganhador 01: leva um Playstation 5',
    },
    {
      question: 'Como saberei quem ganhou os prêmios sorteados?',
      answer: 'Os ganhadores de cada sorteio serão divulgados nos canais de comunicação oficiais da sua empresa a cada sorteio.',
    },
    {
      question: 'Quais prêmios e quantos sorteios acontecerão?',
      answer: 'Os prêmios são: Fone JBL, Smartwatch Haylou, Alexa Echo Pop, Grill Oster, Airfryer, TV 50” Philips e Playstation 5. Seguindo esta ordem: <br /><br /> <u>Sorteio 01:</u><br /> Ganhador 01: leva um Fone JBL <br /><u>Sorteio 02:</u> <br />Ganhador 01: leva um smartwatch Haylou <br /><u>Sorteio 03:</u><br /> Ganhador 01: leva uma Alexa Echo Pop Amazon <br /><u>Sorteio 04:</u><br /> Ganhador 01: leva um grill Oster <br /><u>Sorteio 05:</u><br /> Ganhador 01: leva uma Airfryer Mondial <br /><u>Sorteio 06:</u><br /> Ganhador 01: leva uma TV 50 polegadas Philips <br /><u>Sorteio 07:</u><br /> Ganhador 01: leva um Playstation 5',
    },
    {
      question: 'Meu nome foi sorteado. Quando receberei meus prêmios?',
      answer: 'Os prêmios serão postados em até 20 dias úteis após o último sorteio e a confirmação de endereço completo por parte dos vencedores.',
    },
  ];

  const stageInfo = calculateStage(registrationCount);

  if (institutionalIsValid) {
    return (
      <Container>
        <Helmet>
          <title>
            Ali |
            {' '}
            {pathData.friendlyName ?? ''}
          </title>
        </Helmet>
        <Header>
          <img src={AliIso} alt="Ali logo" style={{ height: '40px' }} />
          <img src={ExpressoNepomucenoLogo} alt="Expresso Nepomuceno logo" style={{ height: '70px' }} />
        </Header>
        <Content>
          <LeftContent>
            <ImageContainer>
              <img src={AlivieOBolsoComAli} alt="Alivie o Bolso" />
            </ImageContainer>
            <h1>Benefício que te ajuda a economizar e ganhar prêmios? Com a Ali você tem!</h1>
            <p>Inscreva-se para ativar seu benefício Ali e concorrer a prêmios incríveis.</p>
            <p>
              <b>Não se preocupe:</b>
              {' '}
              você só utiliza o benefício se quiser, combinado?
            </p>
          </LeftContent>
          <RightContent>
            <SignupForm company={pathData.id} origin="mgm" />
            <img src={HappyWoman} alt="Mulher contente, comemorando uma conquista enquanto segura um celular" style={{ height: '600px' }} />
          </RightContent>
        </Content>
        <BlackDivider>
          <div>
            <img src={BubblesOfPeople} alt="Pessoas" style={{ height: '70px' }} />
          </div>
          <div>
            <h1>Participe e chame seus amigos</h1>
            <h2>para desbloquear mais prêmios</h2>
          </div>
        </BlackDivider>
        <PrizesBox>
          <h1>Você pode ganhar um desses prêmios incríveis</h1>
          <PrizeList>
            <PrizeListContent>
              <Prize>
                <h1>PlayStation 5</h1>
                <img src={Playstation5} alt="PlayStation 5" style={{ height: '150px', width: '250px' }} />
              </Prize>
              <Prize>
                <h1>Smart TV 50&ldquo;</h1>
                <img src={SmartTv} alt="Smart TV" style={{ height: '150px', width: '250px' }} />
              </Prize>
              <Prize>
                <h1>Airfryer</h1>
                <img src={Airfryer} alt="Airfryer" style={{ height: '150px', width: '250px' }} />
              </Prize>
              <Prize>
                <h1>Smartwatch Haylou</h1>
                <img src={SmartwatchXiaomi} alt="Smartwatch Haylou" style={{ height: '150px', width: '250px' }} />
              </Prize>
              <Prize>
                <h1>Alexa Echo Pop</h1>
                <img src={AlexaEchoPop} alt="Alexa Echo Pop" style={{ height: '150px', width: '250px' }} />
              </Prize>
              <Prize>
                <h1>Fone JBL</h1>
                <img src={HeadphoneJbl} alt="Fone JBL" style={{ height: '150px', width: '250px' }} />
              </Prize>
              <Prize>
                <h1>Grill Oster</h1>
                <img src={GrillOster} alt="Grill Oster" style={{ height: '150px', width: '250px' }} />
              </Prize>
            </PrizeListContent>
          </PrizeList>
          <h2>*Imagens meramente ilustrativas</h2>
        </PrizesBox>
        {registrationCount !== null && (
          <CarouselBox>
            <div>
              <h1>Ajude seus amigos a economizar:</h1>
              <h2>Quanto mais pessoas ativarem o benefício, mais prêmios serão desbloqueados.</h2>
              {stageInfo.currentStage === 2800 ? (
                <h1>
                  Todos os sorteios desbloqueados!
                </h1>
              ) : (
                <>
                  <h1>
                    Faltam apenas
                    {' '}
                    {stageInfo.remainingRegistrations}
                    {' '}
                    inscritos
                  </h1>
                  <h2>PARA DESBLOQUEAR O PRÓXIMO SORTEIO</h2>
                </>
              )}
            </div>
            <ExpressoNepomucenoCarousel active={stageInfo.currentStage} activeIndex={stageInfo.currentStageIndex - 1} />
          </CarouselBox>
        )}
        <CampaignProgress>
          <h1>Ajude seus amigos a economizar:</h1>
          <h2>Quanto mais pessoas ativarem o benefício, mais prêmios serão desbloqueados.</h2>
          <Progress>
            {stageInfo.currentStage === 2800 ? (
              <h1>
                Todos os sorteios desbloqueados!
              </h1>
            ) : (
              <>
                <h1>
                  Faltam apenas
                  {' '}
                  {stageInfo.remainingRegistrations}
                  {' '}
                  inscritos
                </h1>
                <h2>PARA DESBLOQUEAR O PRÓXIMO SORTEIO</h2>
              </>
            )}
            <ProgressList id="carousel">
              <ProgressItem>
                <h1>Sorteio 01:</h1>
                <h1>Ganhador 01:</h1>
                <h2>Fone JBL</h2>
                <br />
                <BackgroundCircle
                  active={stageInfo.currentStage === 300}
                >
                  <span />
                  <span />
                  <span />
                  <span />
                </BackgroundCircle>
                <StyledImage
                  src={HeadphoneJbl}
                  alt="Fone JBL"
                  style={stageInfo.currentStage === 300
                    ? { height: '180px', width: '220px' }
                    : { height: '130px', width: '150px' }}
                  active={stageInfo.currentStage < 300}
                />
              </ProgressItem>
              <LineLock large unlock={stageInfo.currentStage >= 600}>
                <span />
                <span />
                <span />
                <h1>--------------</h1>
                {stageInfo.currentStage >= 600
                  ? <LockOpen /> : <Lock />}
              </LineLock>
              <ProgressItem>
                <h1>Sorteio 02:</h1>
                <h1>Ganhador 01:</h1>
                <h2>Smartwatch Haylou</h2>
                <br />
                <BackgroundCircle active={stageInfo.currentStage === 600}>
                  <span />
                  <span />
                  <span />
                  <span />
                </BackgroundCircle>
                <StyledImage
                  src={SmartwatchXiaomi}
                  alt="Smartwatch hayloui"
                  style={stageInfo.currentStage === 600
                    ? { height: '160px', width: '160px' }
                    : { height: '135px', width: '120px', margin: '-115px 20px 0 20px' }}
                  active={stageInfo.currentStage < 600}
                />
              </ProgressItem>
              <LineLock large unlock={stageInfo.currentStage >= 900}>
                <span />
                <span />
                <span />
                <h1>--------------</h1>
                {stageInfo.currentStage >= 900
                  ? <LockOpen /> : <Lock />}
              </LineLock>
              <ProgressItem>
                <h1>Sorteio 03:</h1>
                <h1>Ganhador 01:</h1>
                <h2>Alexa Echo Pop</h2>
                <br />
                <BackgroundCircle active={stageInfo.currentStage === 900}>
                  <span />
                  <span />
                  <span />
                  <span />
                </BackgroundCircle>
                <StyledImage
                  src={AlexaEchoPop}
                  alt="Alexa Echo Pop"
                  style={stageInfo.currentStage === 900
                    ? { height: '150px', width: '250px' }
                    : { height: '110px', width: '130px', marginTop: '-90px' }}
                  active={stageInfo.currentStage < 900}
                />
              </ProgressItem>
              <LineLock large unlock={stageInfo.currentStage >= 1200}>
                <span />
                <span />
                <span />
                <h1>--------------</h1>
                {stageInfo.currentStage >= 1200
                  ? <LockOpen /> : <Lock />}
              </LineLock>
              <ProgressItem>
                <h1>Sorteio 04:</h1>
                <h1>Ganhador 01:</h1>
                <h2>Grill Oster</h2>
                <br />
                <BackgroundCircle active={stageInfo.currentStage === 1200}>
                  <span />
                  <span />
                  <span />
                  <span />
                </BackgroundCircle>
                <StyledImage
                  src={GrillOster}
                  alt="Grill Oster"
                  style={stageInfo.currentStage === 1200
                    ? { height: '160px', width: '250px' }
                    : { height: '110px', width: '140px', marginTop: '-90px' }}
                  active={stageInfo.currentStage < 1200}
                />
              </ProgressItem>
              <LineLock large unlock={stageInfo.currentStage >= 1500}>
                <span />
                <span />
                <span />
                <h1>--------------</h1>
                {stageInfo.currentStage >= 1500
                  ? <LockOpen /> : <Lock />}
              </LineLock>
              <ProgressItem>
                <h1>Sorteio 05:</h1>
                <h1>Ganhador 01:</h1>
                <h2>Airfryer</h2>
                <br />
                <BackgroundCircle active={stageInfo.currentStage === 1500}>
                  <span />
                  <span />
                  <span />
                  <span />
                </BackgroundCircle>
                <StyledImage
                  src={Airfryer}
                  alt="Airfryer"
                  style={stageInfo.currentStage === 1500
                    ? { height: '150px', width: '250px' }
                    : { height: '110px', width: '140px', marginTop: '-90px' }}
                  active={stageInfo.currentStage < 1500}
                />
              </ProgressItem>
              <LineLock large unlock={stageInfo.currentStage >= 2100}>
                <span />
                <span />
                <span />
                <h1>--------------</h1>
                {stageInfo.currentStage >= 2100
                  ? <LockOpen /> : <Lock />}
              </LineLock>
              <ProgressItem>
                <h1>Sorteio 06:</h1>
                <h1>Ganhador 01:</h1>
                <h2>TV 50&ldquo;</h2>
                <br />
                <BackgroundCircle active={stageInfo.currentStage === 2100}>
                  <span />
                  <span />
                  <span />
                  <span />
                </BackgroundCircle>
                <StyledImage
                  src={SmartTv}
                  alt="TV 50 polegadas"
                  style={stageInfo.currentStage === 2100
                    ? { height: '150px', width: '210px', marginTop: '-90px' }
                    : { height: '100px', width: '160px', marginTop: '-90px' }}
                  active={stageInfo.currentStage < 2100}
                />
              </ProgressItem>
              <LineLock large unlock={stageInfo.currentStage >= 2800}>
                <span />
                <span />
                <span />
                <h1>--------------</h1>
                {stageInfo.currentStage >= 2800
                  ? <LockOpen /> : <Lock />}
              </LineLock>
              <ProgressItem>
                <h1>Sorteio 07:</h1>
                <h1>Ganhador 01:</h1>
                <h2>Playstation 5</h2>
                <br />
                <BackgroundCircle active={stageInfo.currentStage === 2800}>
                  <span />
                  <span />
                  <span />
                  <span />
                </BackgroundCircle>
                <StyledImage
                  src={Playstation5}
                  alt="Playstation 5"
                  style={stageInfo.currentStage === 2800
                    ? { height: '160px', width: '210px' }
                    : { height: '120px', width: '150px' }}
                  active={stageInfo.currentStage < 2800}
                />
              </ProgressItem>
            </ProgressList>
            <ArrowsContainer>
              <ArrowButton aria-label="scroll left" onClick={handleScrollLeft} disabled={scrollPosition <= 0}>
                <ChevronLeft />
              </ArrowButton>
              <ArrowButton aria-label="scroll right" onClick={handleScrollRight}>
                <ChevronRight />
              </ArrowButton>
            </ArrowsContainer>
          </Progress>
        </CampaignProgress>
        <FaqBox>
          <div>
            <img src={ManPlaying} alt="Homem jogando" />
          </div>
          <div>
            <h2>Regras da Campanha</h2>
            <FaqContainer>
              {faqData.map((item, index) => (
                <FaqItem key={item.answer}>
                  <Question onClick={() => toggleFaq(index)}>
                    {item.question}
                    <ArrowIcon isOpen={activeIndex === index}><ExpandMoreOutlined /></ArrowIcon>
                  </Question>
                  <Answer
                    isOpen={activeIndex === index}
                    dangerouslySetInnerHTML={{ __html: item.answer }}
                  />
                </FaqItem>
              ))}
            </FaqContainer>
          </div>
        </FaqBox>
        <RegulationBox>
          <Regulation>
            <div>
              <h2>Acesse e confira o regulamento completo</h2>
              <Button href="https://qrco.de/regulamento-expressonepomuceno" target="_blank">
                Consulte aqui
              </Button>
            </div>
            <img src={AliInCellphone} alt="Mulher segurando o celular e abrindo o app da AliCrédito" />
          </Regulation>
        </RegulationBox>
        <Footer>
          <LpFooter />
        </Footer>
      </Container>
    );
  }

  return null;
};

export default ExpressoNepomucenoLandingPageContent;
