/* eslint-disable react/style-prop-object */
import React from 'react';

const Lock = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_15779_2016" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="22">
      <rect x="0.664062" y="0.666992" width="20.6667" height="20.6667" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_15779_2016)">
      <path d="M6.35987 18.7505C5.97453 18.7505 5.6463 18.615 5.37519 18.344C5.10423 18.0729 4.96875 17.7447 4.96875 17.3594V9.80828C4.96875 9.42293 5.10423 9.09471 5.37519 8.8236C5.6463 8.55264 5.97453 8.41715 6.35987 8.41715H7.55208V6.69493C7.55208 5.73551 7.88634 4.92162 8.55485 4.25325C9.22321 3.58474 10.0371 3.25049 10.9965 3.25049C11.9559 3.25049 12.7698 3.58474 13.4382 4.25325C14.1067 4.92162 14.441 5.73551 14.441 6.69493V8.41715H15.6332C16.0185 8.41715 16.3468 8.55264 16.6179 8.8236C16.8888 9.09471 17.0243 9.42293 17.0243 9.80828V17.3594C17.0243 17.7447 16.8888 18.0729 16.6179 18.344C16.3468 18.615 16.0185 18.7505 15.6332 18.7505H6.35987ZM6.35987 17.8894H15.6332C15.7878 17.8894 15.9148 17.8397 16.0142 17.7404C16.1135 17.6409 16.1632 17.5139 16.1632 17.3594V9.80828C16.1632 9.65371 16.1135 9.5267 16.0142 9.42724C15.9148 9.32792 15.7878 9.27827 15.6332 9.27827H6.35987C6.20531 9.27827 6.07829 9.32792 5.97883 9.42724C5.87952 9.5267 5.82986 9.65371 5.82986 9.80828V17.3594C5.82986 17.5139 5.87952 17.6409 5.97883 17.7404C6.07829 17.8397 6.20531 17.8894 6.35987 17.8894ZM10.9965 14.8755C11.3598 14.8755 11.6658 14.751 11.9147 14.502C12.1637 14.2531 12.2882 13.9471 12.2882 13.5838C12.2882 13.2206 12.1637 12.9145 11.9147 12.6657C11.6658 12.4167 11.3598 12.2922 10.9965 12.2922C10.6333 12.2922 10.3272 12.4167 10.0784 12.6657C9.82936 12.9145 9.70486 13.2206 9.70486 13.5838C9.70486 13.9471 9.82936 14.2531 10.0784 14.502C10.3272 14.751 10.6333 14.8755 10.9965 14.8755ZM8.41319 8.41715H13.5799V6.69493C13.5799 5.97734 13.3287 5.36739 12.8264 4.86507C12.3241 4.36276 11.7141 4.1116 10.9965 4.1116C10.2789 4.1116 9.66898 4.36276 9.16667 4.86507C8.66435 5.36739 8.41319 5.97734 8.41319 6.69493V8.41715Z" fill="white" />
    </g>
  </svg>
);

export default Lock;
