import styled from 'styled-components';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  padding: 2rem;
  border-radius: 36px;
  z-index: 2;

  form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
  }

  span, a {
    font-size: 14px;
  }

  @media (min-width: 1100px) {
    padding: 2rem;
  }

  @media (min-width: 1920px) {
    padding: 2rem 47px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 3rem;
`;

export const Button = styled.button`
  display: flex;
  width: 90%;
  padding: 1.5rem;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #0F62FE;
  outline: none;
  border-radius: 50px;
  color: white;
  font-weight: 600;
  font-size: 26px;
  border: none;
  cursor: pointer;

  &:disabled {
    background-color: #cccccc;
    pointer-events: none;
  }

  @media (min-width: 1920px) {
    padding: 2.5rem;
  }
`;
