import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background-color: #0F62FE;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  height: 158px;
  background-color: white;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 2;

  img:first-child {
    padding-right: 2rem;
    margin-right: 1rem;
    height: 50px !important;
    border-right: 1px solid #cacaca;
  }

  @media (min-width: 1100px) {
    img:first-child {
      height: 70px !important;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #0F62FE;
  padding: 0rem 1rem 2rem 1rem;
  min-height: 700px;
  color: #fff;
  max-width: 1920px;

  @media (min-width: 1100px) {
    padding: 115px 40px;
    flex-direction: row;
    align-items: flex-start;
    gap: 2rem;
  }

  @media (min-width: 1920px) {
    padding: 115px 120px;
    flex-direction: row;
    align-items: center;
    gap: 8rem;
  }
`;

export const ImageContainer = styled.div`
  width: 192px;
  height: 150px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (min-width: 1100px) {
    width: 315px;
    height: 245px;
  }

  @media (min-width: 1920px) {
    width: 315px;
    height: 245px;
  }
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #0F62FE;
  padding: 2rem;
  color: #ffffff;
  gap: 1rem;

  h1 {
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 5rem;
    margin-top: 2rem;
  }

  p {
    margin: 0;
    font-weight: 300;
  }

  @media (min-width: 1100px) {
    width: 70%;
    margin-bottom: 0px;
    margin-top: -60px;
    align-items: flex-start;
    text-align: start;

    h1 {
      font-size: 38px;
      font-weight: 500;
    }

    p {
      font-size: 18px;
      margin-bottom: 4rem;
    }
  }

  @media (min-width: 1920px) {
    width: 100%;
    margin-bottom: 0px;
    margin-top: -60px;

    h1 {
      font-size: 54px;
      font-weight: 500;
    }

    p {
      font-size: 28px;
      margin-bottom: 4rem;
    }
  }
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #0F62FE;
  padding: 0rem 1rem;
  color: #000;

  > div {
    z-index: 2;
    max-width: 100%;
    min-width: 260px;
  }

  img {
    display: none;
  }

  @media (min-width: 800px) {
    img {
      display: block;
      position: relative;
      z-index: 1;
      margin-left: -170px;
      width: 50%;
    }
  }


  @media (min-width: 1100px) {
    img {
      width: 60%;
    }

    > div {
      min-width: 460px;
    }
  }

  @media (min-width: 1920px) {
    width: 100%;
    margin-bottom: 0px;
    margin-top: -150px;

    > div {
      z-index: 2;
      width: 567px;
      max-width: 100%;
      height: 704px;
    }

    img {
      position: relative;
      z-index: 1;
      margin-left: -170px;
      width: auto;
    }
  }
`;

export const BlackDivider = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  background: #000;
  padding: 2rem;

  > div > h1 {
    font-size: 18px;
    font-weight: 300;
    color: #fff;
  }

  > div > h2 {
    font-size: 18px;
    font-weight: 300;
    color: #fff;
  }

  img {
    width: 100% !important;
  }

  @media (min-width: 1100px) {
    flex-direction: row;
    gap: 5rem ;
  }
`;

export const CampaignProgress = styled.div`
  display: none;
  

  @media (min-width: 1100px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    width: 100%;
    height: auto;
    background: #fff;
    padding: 4rem 142px;
    
    h1 {
      font-family: 'Poppins', sans-serif;
      font-size: 28px;
      color: #000;
      font-weight: 500;
    }

    h2 {
      font-size: 18px;
      color: #000;
      font-weight: 400;
    }
  }
`;

export const Progress = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  background: #0F62FE;
  padding: 7rem 0rem 2rem 1rem;
  border-radius: 25px;
  margin-top: 40px;
  min-height: 530px;
  overflow: hidden;

  h1 {
    font-family: 'Poppins', sans-serif;
    color: #fff;
    font-size: 28px;
  }

  h2 {
    font-family: 'Poppins', sans-serif;
    color: #e6e6e6;
    font-size: 18px;
    font-weight: 200;
    margin-top: 1rem;
  }

  @media (min-width: 1100px) {
    h1 {
      font-size: 38px;
    }
  }
`;

export const ProgressList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start; 
  margin-top: 40px;
  gap: 0;
  align-items: flex-start;
  width: 100%;
  height: auto;
  min-height: 420px;
  overflow-x: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
  
  &::-webkit-scrollbar {
    display: none;
  }

  & > div:first-child {
    margin-left: 0;
  }
`;

export const ProgressItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  
  h1:first-child {
    font-family: 'Poppins', sans-serif;
    color: #fff;
    font-size: 14px;
    margin-bottom: .5rem;
  }

  h1 {
    font-family: 'Poppins', sans-serif;
    color: #fff;
    font-size: 14px;
  }

  h2 {
    font-family: 'Poppins', sans-serif;
    color: #e6e6e6;
    font-size: 16px;
    font-weight: 200;
    text-align: center;
    margin-bottom: 1rem;
  }

  img {
    position: relative;
    margin-top: -110px;
    z-index: 9;
  }
`;

export const StyledImage = styled.img`
  filter: ${({ active }) => (active ? 'grayscale(100%)' : 'none')};
`;

export const BackgroundCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${(props) => (props.active ? '90px' : '120px')}; 

  span:first-child {
    background-color: #4c87f4;
    width: ${(props) => (props.active ? '200px' : '110px')}; 
    height: ${(props) => (props.active ? '200px' : '110px')}; 
    border-radius: 50%;
    position: absolute;
    z-index: 1;
  }

  span:nth-child(2) {
    background-color: ${(props) => (props.active ? '#89b2fe' : '#001d6c')}; 
    width: ${(props) => (props.active ? '170px' : '100px')};
    height: ${(props) => (props.active ? '170px' : '100px')};
    border-radius: 50%;
    position: absolute;
    z-index: 2;
  }

  span:nth-child(3) {
    background-color: ${(props) => (props.active ? '#acc9fe' : '#0F62FE')};
    width: ${(props) => (props.active ? '150px' : '96px')};
    height: ${(props) => (props.active ? '150px' : '96px')};
    border-radius: 50%;
    position: absolute;
    z-index: 3;
  }

  span:nth-child(4) {
    background-color: #0F62FE;
    width: ${(props) => (props.active ? '135px' : '96px')};
    height: ${(props) => (props.active ? '135px' : '96px')};
    border-radius: 50%;
    position: absolute;
    z-index: 4;
  }
`;

export const LineLock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 205px;
  position: relative;
  width: 100%;

  h1 {
    position: absolute;
    font-size: 12px;
    z-index: 2;
    color: #89b2fe;
    white-space: nowrap;
  }

  span:nth-child(1) {
    background-color: ${(props) => (props.unlock ? '#4c87f4' : '#0a4dd2 ')}; 
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
  }

  span:nth-child(2) {
    background-color: ${(props) => (props.unlock ? '#89b2fe' : '#0f62fe')}; 
    width: 36px;
    height: 36px;
    border-radius: 50%;
    position: absolute;
    z-index: 3;
  }

  span:nth-child(3) {
    background-color: #0043ce; 
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    z-index: 4;
  }

  > svg {
    position: relative;
    width: ${(props) => (props.large ? '110px' : '90px')}; 
    color: #fff;
    z-index: 9;
  }
`;

export const ArrowsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding-right: 5rem;
  width: 100%;
  margin-top: 20px;
`;

export const ArrowButton = styled.button`
  background: none;
  border: none;
  color: #fff;
  width: 36px;
  height: 36px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;

  svg {
    font-size: 28px;
  }

  &:disabled {
    color: #ccc;
    cursor: not-allowed;
  }
`;

export const PrizesBox = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 4rem 40px;
  width: 100%;

  h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 28px;
    color: #000;
    font-weight: 500;
  }

  h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    color: #000;
    font-weight: 500;
    margin: 10px 0 0 50px;
  }
  
  @media (min-width: 1200px) {
    padding: 4rem 142px;
  }
`;

export const PrizeList = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5rem;
  justify-content: center;
`;

export const PrizeListContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  max-width: 1350px;
`;

export const Prize = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  border: 1px solid #0F62FE;
  border-radius: 10px;
  max-width: 398px;
  padding: 1rem;
  
  h1 {
    font-size: 18px;
  }

  img {
    object-fit: contain;
  }
`;

export const FaqBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4rem;
  width: 100%;
  background-color: #F0F2F6;
  padding: 2rem;
  
  h2 {
    font-family: 'Poppins', sans-serif;
    color: #000;
  }

  > div:first-child {
    display: none;
  }

  > div:nth-child(1) {
    margin-left: 20px;
  }

  @media (min-width: 1200px) {
    padding: 8rem 14rem;

    > div:first-child {
      display: flex;
      height: 500px;
    }
  }
`;

export const FaqContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px auto;
`;

export const FaqItem = styled.div`
  margin-bottom: 10px;
  cursor: pointer;
  border-bottom: 1px solid #D3D3D3;
`;

export const Question = styled.h3`
  font-size: 16px;
  color: #333;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #333;
  cursor: pointer;
  padding: 10px;
`;

export const ArrowIcon = styled.span`
  font-size: 18px;
  transition: transform 0.3s ease;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')}; 
`;

export const Answer = styled.p`
  font-size: 16px;
  color: #666;
  margin-top: 0px;
  transition: all 0.3s ease-in-out;
  max-height: ${({ isOpen }) => (isOpen ? '1000px' : '0')};
  overflow: hidden;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  cursor: default;
  padding: 0 20px 0 10px;
`;

export const RegulationBox = styled.div`
  display: flex;
  background-color: #fff;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 4rem;
`;

export const Regulation = styled.div`
  display: flex;
  border-radius: 20px;
  flex-direction: column;
  width: 80%;
  justify-content: space-between;
  background-color: #0F62FE;

  > div {
    padding: 5rem 2rem 5rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;

    > h2 {
      color: #fff;
      font-family: 'Poppins', sans-serif;
      font-size: 18px;
      font-weight: 400;
    }
  }

  > img {
    display: flex;
    max-width: 100%;
  }

  @media (min-width: 1100px) {
    display: flex;
    flex-direction: row;

    > div {
      width: 65%;
      padding: 7rem 6rem 7rem 8rem;
    }

    > div > h2 {
      font-size: 28px;
    }

    > img {
      max-width: 45%;
    }
  }
`;

export const Button = styled.a`
  display: flex;
  width: 100%;
  padding: 1.5rem;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #fff;
  outline: none;
  border-radius: 50px;
  color: black;
  font-weight: 400;
  font-size: 18px !important;
  border: none;
  cursor: pointer;
  margin-top: 3rem;
`;

export const Footer = styled.div`
  > div {
    background-color: #F0F2F6 !important;
  }
`;

export const CarouselBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  background-color: #F0F2F6;
  align-items: center;
  text-align: center;

  > div:first-child {
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;

    > h1 {
      color: black;
      font-family: 'Poppins', sans-serif;
      font-size: 26px;
      margin-top: 5rem;
      font-weight: 400;
    }

    > h2 {
      color: black;
      font-family: 'Poppins', sans-serif;
      font-size: 20px;
      margin-top: 1rem;
      font-weight: 300;
      margin-bottom: 2rem;
    }
    
    > h3 {
      color: black;
      font-family: 'Poppins', sans-serif;
      font-size: 24px;
      margin-top: 1rem;
      font-weight: 600;
    }
    
    > h4 {
      color: black;
      font-family: 'Poppins', sans-serif;
      font-size: 20px;
      font-weight: 300;
      margin-bottom: 4rem;
    }
  }
  
  > div {
    display: flex;
    width: 100%;
  }

  @media (min-width: 1100px) {
    display: none;
  }
`;
