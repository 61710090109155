/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/prop-types */
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {
  BackgroundCircle, Item, LineLock, StyledImage,
} from './styles';
import LockOpen from '../../images/lock-open-solid';
import Lock from '../../images/lock-solid';
import SwitchAndEchoPop from '../../images/switch-echo-pop.png';
import ShowAndPop from '../../images/alexa-show-alexa-pop.png';
import TvAndPop from '../../images/tv-alexa-pop.png';
import TvAndShow from '../../images/tv-alexa-show.png';
import PlayAndPop from '../../images/playstation-alexa-pop.png';
import SwitchAndAlexa from '../../images/nintendo-switch-alexa-show.png';
import SwitchAnd2Pop from '../../images/nintendo-switch-pop-pop.png';
import TvAndPlayAnd2Pop from '../../images/tv-playstation-pop-pop.png';

const PagueMenosCarousel = ({ active }) => {
  const carouselRef = React.useRef();

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      partialVisibilityGutter: 20,
    },
    tablet: {
      breakpoint: { max: 1024, min: 501 },
      items: 2,
      partialVisibilityGutter: 20,
    },
    mobile: {
      breakpoint: { max: 501, min: 0 },
      items: 1,
      partialVisibilityGutter: 20,
    },
  };

  const getSlideIndex = (activeValue) => {
    if (activeValue <= 1000) return 0;
    return Math.floor((activeValue - 1000) / 1000);
  };

  React.useEffect(() => {
    if (carouselRef.current && typeof active === 'number') {
      const slideIndex = getSlideIndex(active);
      carouselRef.current.goToSlide(slideIndex, true);
    }
  }, [active]);

  return (
    <Carousel responsive={responsive} ref={carouselRef} partialVisible>
      <Item>
        <div>
          <h1>Sorteio 01:</h1>
          <h2>Ganhador 01:</h2>
          <h3>NINTENDO SWITCH</h3>
          <h2>Ganhador 02:</h2>
          <h3>ALEXA ECHO POP</h3>
          <BackgroundCircle
            active={active === 1000}
          >
            <span />
            <span />
            <span />
            <span />
          </BackgroundCircle>
          <StyledImage
            src={SwitchAndEchoPop}
            alt="Nintendo Switch e Alexa Echo Pop"
            style={active === 1000
              ? { height: '150px', width: '250px' }
              : { height: '130px', width: '200px' }}
            active={active < 1000}
          />
        </div>
        <div>
          <LineLock large unlock={active >= 1000}>
            <span />
            <span />
            <span />
            <div className="line" />
            {active >= 1000 ? <LockOpen /> : <Lock />}
          </LineLock>
        </div>
      </Item>
      <Item>
        <div>
          <h1>Sorteio 02:</h1>
          <h2>Ganhador 01:</h2>
          <h3>ALEXA ECHO SHOW 5</h3>
          <h2>Ganhador 02:</h2>
          <h3>ALEXA ECHO POP</h3>
          <BackgroundCircle active={active === 2000}>
            <span />
            <span />
            <span />
            <span />
          </BackgroundCircle>
          <StyledImage
            src={ShowAndPop}
            alt="Alexa Echo Show e Alexa Echo Pop"
            style={active === 2000
              ? { height: '160px', width: '250px' }
              : { height: '110px', width: '180px', marginTop: '-90px' }}
            active={active < 2000}
          />
        </div>
        <div>
          <LineLock large unlock={active >= 2000}>
            <span />
            <span />
            <span />
            <div className="line" />
            {active >= 2000 ? <LockOpen /> : <Lock />}
          </LineLock>
        </div>
      </Item>
      <Item>
        <div>
          <h1>Sorteio 03:</h1>
          <h2>Ganhador 01:</h2>
          <h3>TV 50&ldquo;</h3>
          <h2>Ganhador 02:</h2>
          <h3>ALEXA ECHO POP</h3>
          <BackgroundCircle active={active === 3000}>
            <span />
            <span />
            <span />
            <span />
          </BackgroundCircle>
          <StyledImage
            src={TvAndPop}
            alt="TV 50 e Alexa Echo Pop"
            style={active === 3000
              ? { height: '120px', width: '220px' }
              : { height: '110px', width: '190px', marginTop: '-90px' }}
            active={active < 3000}
          />
        </div>
        <div>
          <LineLock large unlock={active >= 3000}>
            <span />
            <span />
            <span />
            <div className="line" />
            {active >= 3000 ? <LockOpen /> : <Lock />}
          </LineLock>
        </div>
      </Item>
      <Item>
        <div>
          <h1>Sorteio 04:</h1>
          <h2>Ganhador 01:</h2>
          <h3>ALEXA ECHO SHOW 5</h3>
          <h2>Ganhador 02:</h2>
          <h3>ALEXA ECHO POP</h3>
          <BackgroundCircle
            active={active === 4000}
          >
            <span />
            <span />
            <span />
            <span />
          </BackgroundCircle>
          <StyledImage
            src={ShowAndPop}
            alt="Alexa Echo Show e Alexa Echo Pop"
            style={active === 4000
              ? { height: '160px', width: '250px' }
              : { height: '110px', width: '180px', marginTop: '-90px' }}
            active={active < 4000}
          />
        </div>
        <div>
          <LineLock large unlock={active >= 4000}>
            <span />
            <span />
            <span />
            <div className="line" />
            {active >= 4000 ? <LockOpen /> : <Lock />}
          </LineLock>
        </div>
      </Item>
      <Item>
        <div>
          <h1>Sorteio 05:</h1>
          <h2>Ganhador 01:</h2>
          <h3>NINTENDO SWITCH</h3>
          <h2>Ganhador 02:</h2>
          <h3>ALEXA ECHO POP</h3>
          <BackgroundCircle
            active={active === 5000}
          >
            <span />
            <span />
            <span />
            <span />
          </BackgroundCircle>
          <StyledImage
            src={SwitchAndEchoPop}
            alt="Nintendo Switch e Alexa Echo Pop"
            style={active === 5000
              ? { height: '150px', width: '250px' }
              : { height: '110px', width: '200px', marginTop: '-90px' }}
            active={active < 5000}
          />
        </div>
        <div>
          <LineLock large unlock={active >= 5000}>
            <span />
            <span />
            <span />
            <div className="line" />
            {active >= 5000 ? <LockOpen /> : <Lock />}
          </LineLock>
        </div>
      </Item>
      <Item>
        <div>
          <h1>Sorteio 06:</h1>
          <h2>Ganhador 01:</h2>
          <h3>PLAYSTATION 5</h3>
          <h2>Ganhador 02:</h2>
          <h3>ALEXA ECHO POP</h3>
          <BackgroundCircle
            active={active === 6000}
          >
            <span />
            <span />
            <span />
            <span />
          </BackgroundCircle>
          <StyledImage
            src={PlayAndPop}
            alt="Playstation e Alexa Echo Pop"
            style={active === 6000
              ? { height: '160px', width: '210px' }
              : { height: '130px', width: '150px' }}
            active={active < 6000}
          />
        </div>
        <div>
          <LineLock large unlock={active >= 6000}>
            <span />
            <span />
            <span />
            <div className="line" />
            {active >= 6000 ? <LockOpen /> : <Lock />}
          </LineLock>
        </div>
      </Item>
      <Item>
        <div>
          <h1>Sorteio 07:</h1>
          <h2>Ganhador 01:</h2>
          <h3>NINTENDO SWITCH</h3>
          <h2>Ganhador 02:</h2>
          <h3>ALEXA ECHO SHOW 5</h3>
          <BackgroundCircle
            active={active === 7000}
          >
            <span />
            <span />
            <span />
            <span />
          </BackgroundCircle>
          <StyledImage
            src={SwitchAndAlexa}
            alt="Nintendo Switch e Alexa Echo Show"
            style={active === 7000
              ? { height: '160px', width: '250px' }
              : { height: '120px', width: '180px' }}
            active={active < 7000}
          />
        </div>
        <div>
          <LineLock large unlock={active >= 7000}>
            <span />
            <span />
            <span />
            <div className="line" />
            {active >= 7000 ? <LockOpen /> : <Lock />}
          </LineLock>
        </div>
      </Item>
      <Item>
        <div>
          <h1>Sorteio 08:</h1>
          <h2>Ganhador 01:</h2>
          <h3>TV 50&ldquo;</h3>
          <h2>Ganhador 02:</h2>
          <h3>ALEXA ECHO SHOW 5</h3>
          <BackgroundCircle
            active={active === 8000}
          >
            <span />
            <span />
            <span />
            <span />
          </BackgroundCircle>
          <StyledImage
            src={TvAndShow}
            alt="TV 50 e Alexa Echo Show"
            style={active === 8000
              ? { height: '120px', width: '230px' }
              : { height: '100px', width: '180px', marginTop: '-90px' }}
            active={active < 8000}
          />
        </div>
        <div>
          <LineLock large unlock={active >= 8000}>
            <span />
            <span />
            <span />
            <div className="line" />
            {active >= 8000 ? <LockOpen /> : <Lock />}
          </LineLock>
        </div>
      </Item>
      <Item>
        <div>
          <h1>Sorteio 09:</h1>
          <h2>Ganhador 01:</h2>
          <h3>NINTENDO SWITCH</h3>
          <h2>Ganhador 02:</h2>
          <h3>ALEXA ECHO POP</h3>
          <h2>Ganhador 03:</h2>
          <h3>ALEXA ECHO POP</h3>
          <BackgroundCircle
            active={active === 9000}
          >
            <span />
            <span />
            <span />
            <span />
          </BackgroundCircle>
          <StyledImage
            src={SwitchAnd2Pop}
            alt="Nintendo Switch e 2 Alexa Echo Pop"
            style={active === 9000
              ? { height: '160px', width: '250px' }
              : { height: '110px', width: '190px', marginTop: '-90px' }}
            active={active < 9000}
          />
        </div>
        <div>
          <LineLock large unlock={active >= 9000}>
            <span />
            <span />
            <span />
            <div className="line" />
            {active >= 9000 ? <LockOpen /> : <Lock />}
          </LineLock>
        </div>
      </Item>
      <Item>
        <div>
          <h1>Sorteio 10:</h1>
          <h2>Ganhador 01:</h2>
          <h3>TV 50&ldquo; + PLAYSTATION 5</h3>
          <h2>Ganhador 02:</h2>
          <h3>ALEXA ECHO POP</h3>
          <h2>Ganhador 03:</h2>
          <h3>ALEXA ECHO POP</h3>
          <BackgroundCircle
            active={active === 10000}
          >
            <span />
            <span />
            <span />
            <span />
          </BackgroundCircle>
          <StyledImage
            src={TvAndPlayAnd2Pop}
            alt="TV 50, Playsation 5 e 2 Alexa Echo Pop"
            style={active === 10000
              ? { height: '125px', width: '220px' }
              : { height: '130px', width: '190px' }}
            active={active < 10000}
          />
        </div>
        <div>
          <LineLock large unlock={active >= 10000}>
            <span />
            <span />
            <span />
            <div className="line" />
            {active >= 10000 ? <LockOpen /> : <Lock />}
          </LineLock>
        </div>
      </Item>
    </Carousel>
  );
};

export default PagueMenosCarousel;
