import styled from 'styled-components';

export const Item = styled.div`
  background-color: #F0F2F6;
  padding: 1rem;

  img {
    position: relative;
    margin-top: -115px;
    z-index: 9;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
  }

  > div:first-child {
    background-color: rgb(15, 98, 254);;
    padding: 4rem 1rem;
    border-radius: 20px;
    align-items: center;
    text-align: center;
    height: 460px;

    > h1 {
      font-family: 'Poppins', sans-serif;
      color: white;
      text-transform: uppercase;
      font-size: 20px;
      letter-spacing: 1px;
      margin-bottom: 1rem;
    }

    > h2 {
      font-family: 'Poppins', sans-serif;
      color: white;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1px;
    }

    > h3 {
      font-family: 'Poppins', sans-serif;
      color: white;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 1px;
    }
  }
`;

export const BackgroundCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 160px; 

  span:first-child {
    background-color: #4c87f4;
    width: ${(props) => (props.active ? '200px' : '110px')}; 
    height: ${(props) => (props.active ? '200px' : '110px')}; 
    border-radius: 50%;
    position: absolute;
    z-index: 1;
  }

  span:nth-child(2) {
    background-color: ${(props) => (props.active ? '#89b2fe' : '#001d6c')}; 
    width: ${(props) => (props.active ? '170px' : '100px')};
    height: ${(props) => (props.active ? '170px' : '100px')};
    border-radius: 50%;
    position: absolute;
    z-index: 2;
  }

  span:nth-child(3) {
    background-color: ${(props) => (props.active ? '#acc9fe' : '#0F62FE')};
    width: ${(props) => (props.active ? '150px' : '96px')};
    height: ${(props) => (props.active ? '150px' : '96px')};
    border-radius: 50%;
    position: absolute;
    z-index: 3;
  }

  span:nth-child(4) {
    background-color: #0F62FE;
    width: ${(props) => (props.active ? '135px' : '96px')};
    height: ${(props) => (props.active ? '135px' : '96px')};
    border-radius: 50%;
    position: absolute;
    z-index: 4;
  }
`;

export const StyledImage = styled.img`
  filter: ${({ active }) => (active ? 'grayscale(100%)' : 'none')};
`;

export const LineLock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 20px;
  position: relative;
  width: 100%;

  .line {
    position: absolute;
    width: 100%;
    height: 1px;
    background: repeating-linear-gradient(
      to right,
      #a3a3a3,
      #a3a3a3 5px,
      transparent 5px,
      transparent 10px
    );
    z-index: 1;
  }

  span:nth-child(1) {
    background-color: ${(props) => (props.unlock ? '#4c87f4' : '#0a4dd2')};
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
  }

  span:nth-child(2) {
    background-color: ${(props) => (props.unlock ? '#89b2fe' : '#0f62fe')};
    width: 36px;
    height: 36px;
    border-radius: 50%;
    position: absolute;
    z-index: 3;
  }

  span:nth-child(3) {
    background-color: #0043ce;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    z-index: 4;
  }

  > svg {
    position: relative;
    width: ${(props) => (props.large ? '110px' : '90px')};
    color: #fff;
    z-index: 9;
  }
`;
